import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import api from "api";
import { CardActions } from "@material-ui/core";

class Login extends React.Component {
  state = {
    isFetching: true,
    isSaving: false,
    userInfo: null,
    username: localStorage.getItem("user_session"),
    body_weight: localStorage.getItem("body_weight"),
    feedback_open: false,
    email: null,
  };

  componentDidMount() {
    // Retrieve user info
    api.getUserInfo().then((userInfo) => {
      this.setState({ ...this.state, userInfo: userInfo });
      if (userInfo.body_weight != null) {
        this.setState({ ...this.state, body_weight: userInfo.body_weight });
      }
      if (userInfo.email != null) {
        this.setState({ ...this.state, email: userInfo.email });
      }
    });
  }

  componentDidUpdate = () => {
    const isFetching = this.state.userInfo === null;
    if (isFetching !== this.state.isFetching) {
      this.setState({ ...this.state, isFetching: isFetching });
    }
  };

  handleWeightChange = (event) => {
    this.setState({ ...this.state, body_weight: event.target.value });
  };

  handleEmailChange = (event) => {
    this.setState({ ...this.state, email: event.target.value });
  };

  _validateEmail(email) {
    // eslint-disable-next-line
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleSaveClick = (event) => {
    this.setState({ ...this.state, isSaving: true });

    event.preventDefault();

    let userInfo = { username: this.state.username };
    if (this.state.body_weight !== null) {
      let weight = parseFloat(this.state.body_weight);
      if (!isNaN(weight)) {
        userInfo.body_weight = weight;
      } else {
        alert("Invalid weight value");
        return;
      }
    } else {
      alert("Put your weight!");
      return;
    }

    if (this.state.email !== null) {
      let email = this.state.email;
      if (this._validateEmail(email)) {
        userInfo.email = email;
      } else {
        alert("Invalid email value");
        return;
      }
    } else {
      alert("Add your email!");
    }

    api.saveUserInfo(userInfo).then((_) => {
      this.setState({ ...this.state, isSaving: false, feedback_open: true });
    });
  };

  onFeedbackClosed = () => {
    this.setState({ ...this.state, feedback_open: false });
  };

  render() {
    const { classes } = this.props;
    return this.state.isFetching ? (
      <div align="center">
        <CircularProgress color="secondary" />
      </div>
    ) : (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardHeader>{this.state.username}&apos;s profile</CardHeader>
              <CardContent style={{ textAlign: "center" }}>
                <TextField
                  id="body_weight"
                  value={this.state.body_weight || ""}
                  onChange={this.handleWeightChange}
                  fullWidth={true}
                  type="number"
                  label="Weight"
                  InputProps={{
                    endAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                  }}
                  InputLabelProps={{ shrink: this.state.body_weight != null }}
                />
                <TextField
                  id="email"
                  value={this.state.email || ""}
                  onChange={this.handleEmailChange}
                  fullWidth={true}
                  type="email"
                  label="Email"
                  InputLabelProps={{ shrink: this.state.email != null }}
                />
              </CardContent>

              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.title}
                  onClick={this.handleSaveClick}
                  disabled={this.state.isSaving}
                >
                  <Icon>save</Icon>
                  &nbsp;Save
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.feedback_open}
          onClose={this.onFeedbackClosed}
          autoHideDuration={5000}
          message="Profile updated! 🎉"
        />
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Login);
