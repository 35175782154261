// core components/views
import AddWorkoutPage from "views/AddWorkout/AddWorkout.jsx";
import SummaryPage from "views/Summary/Summary.jsx";
import LoginPage from "views/Login/Login.jsx";
import ProfilePage from "views/Profile/Profile.jsx";

const routes = [
  {
    path: "/summary",
    navbarName: "Summary",
    icon: "poll",
    component: SummaryPage,
    private: true,
  },
  {
    path: "/new-workout",
    navbarName: "New Workout", // Used to put title in page header
    icon: "add_box",
    component: AddWorkoutPage,
    private: true,
  },
  {
    path: "/login",
    navbarName: "Login",
    component: LoginPage,
  },
  {
    path: "/profile",
    navbarName: "Profile",
    icon: "person",
    component: ProfilePage,
    private: true,
  },
  {
    path: "/",
    redirect: true,
    navbarName: "Redirect",
    to: "/summary",
  },
  {
    path: "/dashboard",
    redirect: true,
    navbarName: "Redirect",
    to: "/summary",
  },
];

export default routes;
