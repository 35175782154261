import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import TextField from "@material-ui/core/TextField";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class Login extends React.Component {
  state = {
    name: localStorage.getItem("user_session"),
    body_weight: localStorage.getItem("body_weight"),
  };

  handleNameChange = (event) => {
    this.setState({ ...this.state, name: event.target.value });
  };
  handleWeightChange = (event) => {
    this.setState({ ...this.state, body_weight: event.target.value });
  };

  handleLoginClick = (event) => {
    if (this.state.name !== null) {
      localStorage.setItem("user_session", this.state.name);
      window.location = "/summary";
    } else {
      alert("Say your name!");
    }
    event.preventDefault();
  };

  handleWeightClick = (event) => {
    if (this.state.body_weight !== null) {
      let weight = parseFloat(this.state.body_weight);
      if (!isNaN(weight)) {
        localStorage.setItem("body_weight", weight);
      } else {
        alert("Invalid");
      }
    } else {
      alert("Put your weight!");
    }
    event.preventDefault();
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>What is your name?</CardContent>
              <CardContent style={{ textAlign: "center" }}>
                <TextField
                  id="username"
                  value={this.state.name}
                  onChange={this.handleNameChange}
                  fullWidth={true}
                  type="text"
                />
              </CardContent>
              <CardActions>
                <Button color="primary" variant="contained" className={classes.title} onClick={this.handleLoginClick}>
                  <Icon>send</Icon>
                  &nbsp;Login
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Login);
