import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import DeleteIcon from "@material-ui/icons/Delete";
// business components
import Exercise from "./Exercise.jsx";

const styles = (theme) => {
  return {
    exerciseCard: {
      margin: `${theme.spacing(1)}px auto`,
    },
    actions: {
      flexDirection: "row-reverse",
    },
  };
};

class Workout extends React.Component {
  constructor(props) {
    super(props);

    const currentAvailableExercises = this.calcCurrentAvailableExercises();
    this.state = {
      open: false,
      exercise: "",
      currentAvailableExercises: currentAvailableExercises,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleAdd = () => {
    if (this.state.exercise) {
      this.props.onExerciseAdded(this.state.exercise, 5, 5, 0);
      const newCurrentAvailableExercises = this.calcCurrentAvailableExercises();
      this.setState({
        open: false,
        exercise: "",
        currentAvailableExercises: newCurrentAvailableExercises,
      });
    }
  };

  handleRemove = (exerciseId, name) => {
    const isSerieEmpty = !this.props.workout[exerciseId].series.some((serie) => serie.done === true);
    if (isSerieEmpty || window.confirm(`Are you sure you want to remove ${name}?`)) {
      this.props.onExerciseRemoved(exerciseId);
      this.setState({
        currentAvailableExercises: this.calcCurrentAvailableExercises(),
      });
    }
  };

  handleChange = (event) => {
    this.setState({ exercise: event.target.value });
  };

  exercises = () =>
    this.state.currentAvailableExercises.reduce(
      (acc, exercise) => ({
        ...acc,
        [exercise.type]: acc[exercise.type] ? acc[exercise.type].concat([exercise]) : [exercise],
      }),
      {}
    );

  typeName = (exerciseType) => {
    switch (exerciseType) {
      case "CARDIO":
        return "Cardio";
      case "TIME_BASED":
        return "Timed";
      default:
        return "Reps";
    }
  };

  calcCurrentAvailableExercises = () => {
    const { workout, availableExercises } = this.props;
    const done = Object.keys(workout);
    let currentAvailableExercises = availableExercises.filter((exercise) => !done.includes(exercise.id));
    currentAvailableExercises.sort((a, b) => a.id.localeCompare(b.id));
    return currentAvailableExercises;
  };

  render() {
    const { workout, workoutOrder, availableExercises, date, progress } = this.props;
    let exercises = Object.keys(workout).sort((e1, e2) => {
      return workoutOrder.indexOf(e1) - workoutOrder.indexOf(e2);
    });

    return (
      <div>
        <Dialog disableBackdropClick disableEscapeKeyDown open={this.state.open} onClose={this.handleClose}>
          <DialogTitle>Fill the form</DialogTitle>
          <DialogContent>
            <form>
              <FormControl>
                <InputLabel htmlFor="exercise-native-simple">Exercise</InputLabel>
                <Select
                  native
                  value={this.state.exercise}
                  onChange={this.handleChange}
                  input={<Input id="exercise-native-simple" />}
                >
                  <option aria-label="None" value="" />
                  {Object.keys(this.exercises()).map((k) => (
                    <optgroup label={this.typeName(k)} key={k}>
                      {this.exercises()[k].map((e) => (
                        <option value={e.id} key={e.id}>
                          {e.name}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </Select>
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleAdd} color="primary" disabled={this.state.exercise === ""}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <div>{date}</div>

        {exercises.map((exerciseId) => {
          const exerciseData = availableExercises.find(({ id }) => id === exerciseId);
          return (
            <Card key={exerciseId} className={this.props.classes.exerciseCard}>
              <CardHeader
                title={exerciseData.name}
                action={
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => {
                      this.handleRemove(exerciseId, exerciseData.name);
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                }
              />
              <CardContent>
                <Exercise
                  id={exerciseData.id}
                  type={exerciseData.type}
                  series={workout[exerciseId].series}
                  progress={progress[exerciseId] || []}
                  onRepetitionsChange={this.props.onRepetitionsChange}
                  onWeightChange={this.props.onWeightChange}
                  onDoneChange={this.props.onDoneChange}
                />
              </CardContent>
            </Card>
          );
        })}
        <div align="right">
          <Button variant="contained" color="secondary" onClick={this.handleClickOpen}>
            <LibraryAddIcon />
            &nbsp;Add exercise
          </Button>
        </div>
      </div>
    );
  }
}

Workout.propTypes = {
  classes: PropTypes.object.isRequired,
  workout: PropTypes.object.isRequired,
  workoutOrder: PropTypes.array.isRequired,
  progress: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  availableExercises: PropTypes.array.isRequired,
  onRepetitionsChange: PropTypes.func.isRequired,
  onWeightChange: PropTypes.func.isRequired,
  onDoneChange: PropTypes.func.isRequired,
  onExerciseAdded: PropTypes.func.isRequired,
  onExerciseRemoved: PropTypes.func.isRequired,
};

export default withStyles(styles)(Workout);
