import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  container: {
    textAlign: "center",
    paddingTop: "24px",
    fontSize: "12px",
  },
};

export default withStyles(styles)(function Copyright(props) {
  const { classes } = props;
  return (
    <p className={classes.container}>
      <span>
        Made with{" "}
        <span role="img" aria-label="love">
          ❤️
        </span>{" "}
        by <a href="https://www.aaron.com.es">Aar&oacute;n</a> &amp;{" "}
        <a href="http://www.angelcalvo.com">&Aacute;ngel</a>
      </span>
    </p>
  );
});
