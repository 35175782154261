import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";

const styles = () => ({
  inputCenter: {
    textAlign: "center",
  },
});

const xStyle = {
  padding: "6px 0 7px",
};

class Serie extends React.Component {
  handleTextInputClick = (event) => {
    if (event.target.tagName === "INPUT") {
      event.target.select();
    }
  };

  handleRepetitionsChange = (event) => {
    this.props.onRepetitionsChange(this.props.exerciseId, this.props.index, parseInt(event.target.value, 10));
  };

  handleWeightChange = (event) => {
    this.props.onWeightChange(this.props.exerciseId, this.props.index, parseFloat(event.target.value));
  };

  handleDoneChange = (event) => {
    this.props.onDoneChange(this.props.exerciseId, this.props.index, event.target.checked);
  };

  render() {
    const progress = this.props.progress || 0;

    const progressIcon =
      progress === 0 ? (
        ""
      ) : progress > 0 ? (
        <Icon color="primary">arrow_upward</Icon>
      ) : (
        <Icon color="primary">arrow_downward</Icon>
      );

    return (
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <TextField
            value={this.props.repetitions}
            onChange={this.handleRepetitionsChange}
            onClick={this.handleTextInputClick}
            fullWidth={true}
            InputProps={{
              classes: {
                input: this.props.classes.inputCenter,
              },
            }}
            type="number"
          />
        </Grid>
        <Grid item xs={1}>
          <div align="center" style={xStyle}>
            ⨯
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
              classes: {
                input: this.props.classes.inputCenter,
              },
            }}
            value={this.props.weight}
            onChange={this.handleWeightChange}
            onClick={this.handleTextInputClick}
            fullWidth={true}
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <Checkbox value="done" checked={this.props.done} onChange={this.handleDoneChange} />
          {this.props.done ? progressIcon : ""}
          {this.props.done && progress !== 0 ? Number(progress).toFixed(0) + "%" : ""}
        </Grid>
      </Grid>
    );
  }
}

Serie.propTypes = {
  classes: PropTypes.object.isRequired,
  repetitions: PropTypes.number.isRequired,
  weight: PropTypes.number.isRequired,
  exerciseId: PropTypes.string,
  index: PropTypes.number,
  onRepetitionsChange: PropTypes.func.isRequired,
  onWeightChange: PropTypes.func.isRequired,
  onDoneChange: PropTypes.func.isRequired,
  progress: PropTypes.number,
  done: PropTypes.bool,
};

export default withStyles(styles)(Serie);
