/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Copyright from "components/Copyright.jsx";

import routes from "routes/index.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("user_session") !== null ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.redirect) {
        return <Redirect from={prop.path} to={prop.to} key={key} />;
      }
      if (prop.private) {
        return <PrivateRoute path={prop.path} component={prop.component} key={key} />;
      }
      // Public
      return <Route path={prop.path} component={(props) => <prop.component {...props} />} key={key} />;
    })}
  </Switch>
);

class App extends React.Component {
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      localStorage.setItem("current_path", e.history.location.pathname);
    }
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Header routes={routes} {...rest} />
        <div className={classes.content}>
          <div className={classes.container}>{switchRoutes}</div>
          <Copyright />
        </div>
        <footer className={classes.footer}>
          <Footer {...rest} />
        </footer>
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(App);
