import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Workout from "components/Workout.jsx";
import api from "api";

const LS_WORKOUT = "tmpWorkout";

const today = () => new Date().toISOString().split("T")[0];

const createWorkoutFromPlan = (plan) => {
  return _.cloneDeep(plan.exercises);
};

const createWorkoutFromLatest = (apiWorkout) => {
  let workout = _.cloneDeep(apiWorkout);
  for (const exercise in workout) {
    workout[exercise].series.forEach((it) => {
      it.done = true;
    });
  }
  return workout;
};

const sortByDone = (workout) => {
  return (e1, e2) => {
    let w1 = workout[e1].series.filter((it) => it.done === true).length;
    let w2 = workout[e2].series.filter((it) => it.done === true).length;
    return w2 - w1;
  };
};

class AddWorkout extends React.Component {
  state = {
    isSaving: false,
    isFetching: true,
    workout: null,
    workoutOrder: null,
    availableExercises: null,
    date: today(),
    progress: {},
    updateTime: null,
  };

  componentDidMount() {
    api.getAvailableExercises().then((availableExercises) => {
      this.setState({ availableExercises: availableExercises });
    });

    // Read workout from local storage
    let localWorkout = localStorage.getItem(LS_WORKOUT);
    if (localWorkout !== null) {
      localWorkout = JSON.parse(localWorkout);
      if (localWorkout?.date !== today()) {
        localWorkout = null;
      } else {
        this.setState({
          workout: localWorkout.done,
          workoutOrder: Object.keys(localWorkout.done).sort(sortByDone(localWorkout.done)),
          updateTime: localWorkout.update_time,
        });
      }
    }

    api.getLatestWorkout().then((apiWorkout) => {
      if (this.state.workout === null && apiWorkout === null) {
        api.getNewPlan().then((p) => {
          this.setState({ workout: createWorkoutFromPlan(p), workoutOrder: Object.keys(p.exercises) });
        });
      } else if (
        apiWorkout !== null &&
        (this.state.workout === null || this.state.updateTime < apiWorkout.update_time)
      ) {
        this.setState({
          workout: createWorkoutFromLatest(apiWorkout.done),
          workoutOrder: Object.keys(apiWorkout.done),
          progress: apiWorkout.progress,
          updateTime: apiWorkout.update_time,
        });
      }
    });
  }

  componentDidUpdate = () => {
    const isFetching = this.state.workout === null || this.state.availableExercises === null;

    if (isFetching !== this.state.isFetching) {
      this.setState({ isFetching: isFetching });
    }
  };

  goToSummary = () => {
    document.location = "/summary";
  };

  onRepetitionsChange = (exercise, index, repetitions) => {
    var newState = { ...this.state };

    // Always update the serie at index
    newState.workout[exercise].series[index].repetitions = repetitions;

    // Update also future series not completed yet
    newState.workout[exercise].series.forEach((serie, i) => {
      if (i > index && !serie.done) {
        serie.repetitions = repetitions;
      }
    });
    this.store(newState);
  };

  onWeightChange = (exercise, index, weight) => {
    var newState = { ...this.state };

    // Always update the serie at index
    newState.workout[exercise].series[index].weight = weight;

    // Update also future series not completed yet
    newState.workout[exercise].series.forEach((serie, i) => {
      if (i > index && !serie.done) {
        serie.weight = weight;
      }
    });
    this.store(newState);
  };

  onDoneChange = (exercise, index, done) => {
    var newState = { ...this.state };
    let series = newState.workout[exercise].series;
    series[index].done = done;
    if (series.every((s) => s.done)) {
      series.push({ ...series[series.length - 1], done: false });
    }
    newState.workoutOrder.sort(sortByDone(newState.workout));
    this.store(newState);
  };

  onExerciseAdded = (exercise, series, reps, weight) => {
    var newState = { ...this.state };
    if (!(exercise in newState.workout)) {
      newState.workout[exercise] = {
        series: _.times(series, function () {
          return {
            weight: weight,
            repetitions: reps,
          };
        }),
      };
      newState.workoutOrder.push(exercise);
    }
    // TODO shoudln't be necessary to post api, as it only changes the plan
    this.store(newState);
  };

  onExerciseRemoved = (exercise) => {
    var newState = { ...this.state };
    if (exercise in newState.workout) {
      delete newState.workout[exercise];
      newState.workoutOrder = newState.workoutOrder.filter((it) => it !== exercise);
    }
    this.store(newState);
  };

  store = (newState) => {
    this.setState(newState);
    this.setState({ isSaving: true });

    let newWorkout = {
      done: _.cloneDeep(this.state.workout),
      date: newState.date,
    };

    api.createWorkout(newWorkout).then((r) => {
      let localWorkout = {
        done: newState.workout,
        date: newState.date,
        update_time: r.workout.update_time,
      };
      localStorage.setItem(LS_WORKOUT, JSON.stringify(localWorkout));

      this.setState({
        progress: r.workout.progress,
        updateTime: r.workout.update_time,
        isSaving: false,
      });
    });
  };

  render() {
    const { isFetching, workout, workoutOrder, progress, date, availableExercises } = this.state;
    return isFetching ? (
      <div align="center">
        <CircularProgress color="secondary" />
      </div>
    ) : (
      <div>
        <div>
          <Workout
            date={date}
            workout={workout}
            workoutOrder={workoutOrder}
            progress={progress}
            availableExercises={availableExercises}
            onRepetitionsChange={this.onRepetitionsChange}
            onWeightChange={this.onWeightChange}
            onDoneChange={this.onDoneChange}
            onExerciseAdded={this.onExerciseAdded}
            onExerciseRemoved={this.onExerciseRemoved}
          />
        </div>
      </div>
    );
  }
}

AddWorkout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles({
  bottomButton: { textAlign: "center", marginTop: "50px" },
})(AddWorkout);
