import _ from "lodash";

const getRandomExercise = (series = 5) => {
  return {
    series: _.times(series, function () {
      return {
        weight: _.random(100, 150),
        repetitions: 5,
      };
    }),
  };
};

const getRandomDiff = (series = 5) => {
  return _.times(series, function () {
    return _.random(-20, 100);
  });
};

const STUBS = {
  "GET_/v0/plan": (_body) => {
    return {
      exercises: {
        PULL_UP: getRandomExercise(),
        PUSH_UP: getRandomExercise(),
        PLANK: getRandomExercise(),
      },
    };
  },
  "GET_/v0/workout/latest": (_body) => {
    return {
      username: "aaron",
      date: new Date().toISOString().split("T")[0],
      done: {
        PULL_UP: getRandomExercise(),
        PUSH_UP: getRandomExercise(),
        PLANK: getRandomExercise(),
      },
      context: {
        body_weight: 80,
      },
      update_time: Date.now(),
      progress: {
        PULL_UP: getRandomDiff(),
        PUSH_UP: getRandomDiff(),
        PLANK: getRandomDiff(),
      },
    };
  },
  "POST_/v0/workout": (body) => {
    return {
      success: true,
      workout: {
        username: "aaron",
        update_time: Date.now(),
        done: { ...body },
        progress: {
          PULL_UP: getRandomDiff(),
          PUSH_UP: getRandomDiff(),
          PLANK: getRandomDiff(),
        },
      },
    };
  },
  "GET_/v0/stats": (_body) => {
    return {
      charts: [
        {
          title: "Overall",
          data: {
            labels: ["M", "T", "W", "T", "F", "S", "S"],
            series: [[12, 17, 7, 17, 23, 18, 38]],
            description: "Bla bla bla...",
          },
        },
        {
          title: "Squats",
          data: {
            labels: ["T", "E", "S", "T", "I", "N", "G"],
            series: [[12, 17, 7, 17, 23, 18, 90]],
            description: "Ble ble ble...",
          },
        },
      ],
      single: [
        {
          icon: "check_circle",
          title: "Strike",
          value: "0",
          description: "Bli bli bli",
          action: { text: "Go!", to: "/new-workout" },
          footer: "Blu blu blu",
        },
      ],
      tables: [
        {
          title: "Today's TOP 🔝",
          headers: ["Name", "Magic Points"],
          rows: [
            ["aaron", "13000"],
            ["angel", "5000"],
            ["rafa", "1000"],
          ],
          description: "Show must go on!",
        },
      ],
      ranking: {
        title: "Daily Ranking 🔝",
        description: "Show must go on!",
        results: [
          {
            name: "aaron",
            points: 1000,
            workout: {
              PULL_UP: getRandomExercise(),
              PUSH_UP: getRandomExercise(),
              PLANK: getRandomExercise(),
            },
          },
          {
            name: "angel",
            points: 5000,
            workout: {
              PULL_UP: getRandomExercise(),
              PUSH_UP: getRandomExercise(),
              PLANK: getRandomExercise(),
            },
          },
          {
            name: "rafa",
            points: 1000,
            workout: {
              PULL_UP: getRandomExercise(),
              PUSH_UP: getRandomExercise(),
              PLANK: getRandomExercise(),
            },
          },
        ],
      },
    };
  },
  "GET_/v0/exercises": (_body) => [
    { id: "PULL_UP", name: "Frendly Pull Up", type: "DEFAULT" },
    { id: "PUSH_UP", name: "Flexion", type: "DEFAULT" },
    { id: "PLANK", name: "Plancha bonita", type: "TIME_BASED" },
    { id: "SIDE_PLANK", name: "Side Plank", type: "TIME_BASED" },
  ],
  "GET_/v0/users/me": (_body) => {
    return {
      username: "aaron",
      body_weight: 78,
      email: "aaron@test.com",
    };
  },
  "POST_/v0/users/me": (_body) => {
    return {
      success: true,
    };
  },
};

export function getStub(method, verb, body) {
  return STUBS[verb + "_" + method](body);
}
