import _ from "lodash";
import { getStub } from "stubs";

const api = {
  getNewPlan: () => call("/v0/plan", "GET", null),
  getLatestWorkout: () => call("/v0/workout/latest", "GET", null),
  createWorkout: (workout) => call("/v0/workout", "POST", createWorkoutToServer(workout)),
  getStats: () => call("/v0/stats", "GET", null),
  getAvailableExercises: () => call("/v0/exercises", "GET", null),
  getUserInfo: () => call("/v0/users/me", "GET", null),
  saveUserInfo: (userInfo) => call("/v0/users/me", "POST", userInfo),
};

const createWorkoutToServer = (workout) => {
  for (var exercise in workout.done) {
    workout.done[exercise].series = workout.done[exercise].series
      .filter((serie) => serie.done)
      .map((serie) => ({
        repetitions: serie.repetitions,
        weight: serie.weight,
      }));
  }
  return workout;
};

const call = (route, method, body) => {
  return process.env.REACT_APP_APISTUBS === "true" ? fakeCall(route, method, body) : apiCall(route, method, body);
};

const apiCall = (route, method, body) => {
  // TODO Handle body
  const requestObject = {
    method: method,
    cors: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("user_session"), // TODO Clean this user_session retrieval
    },
  };
  if (body) {
    requestObject.body = JSON.stringify(body);
  }
  return fetch(process.env.REACT_APP_APIURL + route, requestObject).then((response) => {
    return response.json();
  });
};

const fakeCall = (method, verb, body) => {
  let content = getStub(method, verb, body);

  console.debug(
    "%c" + verb + "%c" + method,
    "background-color: #8080ff; font-weight: bold; border-radius: 4px; padding: 0px 2px; margin-right: 4px;",
    ""
  );
  console.groupCollapsed("Response");
  console.debug(JSON.stringify(content, null, 2));
  console.groupEnd();

  return new Promise(function (resolve, _reject) {
    setTimeout(() => resolve(content), _.random(100, 2000));
  });
};

export default api;
