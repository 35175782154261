import { container } from "assets/jss/material-dashboard-react.jsx";

const appStyle = _theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  content: {
    marginTop: "70px",
    padding: "30px 0px",
    minHeight: "calc(100vh - 123px)"
  },
  container,
  map: {
    marginTop: "70px"
  }
});

export default appStyle;
