import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
// @material-ui/icons
import Icon from "@material-ui/core/Icon";
// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import { Link as RouterLink } from "react-router-dom";

import api from "api";

import { dailySalesChart } from "variables/charts.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class Summary extends React.Component {
  state = {
    isFetching: true,
    data: {},
    modal: {
      open: false,
      title: "",
      body: "",
    },
  };

  componentDidMount() {
    api.getStats().then((response) => {
      this.setState({ data: response, isFetching: false });
    });
  }

  handleModalOpen = (name) => {
    const result = this.state.data.ranking.results.find((e) => e.name === name);

    const modalBody = (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Exercise</TableCell>
            <TableCell>Series</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(result.workout)
            .filter((exercise) => result.workout[exercise].series.length > 0)
            .map((exercise, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{exercise}</TableCell>
                  <TableCell>
                    {result.workout[exercise].series.map((serie, i) => {
                      return (
                        <div key={i} align="center">
                          {serie.repetitions}⨯{serie.weight}
                        </div>
                      );
                    })}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    );

    this.setState({
      modal: {
        ...this.state.modal,
        open: true,
        title: name + " Weekly Workout",
        body: modalBody,
      },
    });
  };

  handleModalClose = () => {
    this.setState({
      modal: { ...this.state.modal, open: false },
    });
  };

  createAction = (action) => {
    switch (action.type) {
      case "nav":
        return (
          <Button variant="contained" color="primary" component={RouterLink} to={action.to}>
            {action.text}
          </Button>
        );
      case "share":
        return navigator.share ? (
          <Button
            variant="outlined"
            onClick={() => this.share(action.shareText)}
            startIcon={<Icon>share</Icon>}
            size="small"
          >
            {action.text}
          </Button>
        ) : (
          <div></div>
        );
      default:
        return <div></div>;
    }
  };

  share = (text) => {
    if (navigator.share) {
      navigator
        .share({
          text: text,
          url: "https://gogympal.com/",
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    }
  };

  MUSCLE_MAP = {
    "traps-a": null,
    "traps-b": null,
    "shoulders-a": "SHOULDERS",
    "shoulders-b": "SHOULDERS",
    pecs: "CHEST",
    "biceps-a": "BICEPS",
    "biceps-b": "BICEPS",
    "forearm-a": null,
    "forearm-b": null,
    obliques: "ABS",
    "quads-a": "QUADS",
    "quads-b": "QUADS",
    "calves-a": null,
    "calves-b": null,
    "back-traps-a": "BACK",
    "back-traps-b": "BACK",
    "back-shoulders-a": "SHOULDERS",
    "back-shoulders-b": "SHOULDERS",
    "triceps-a": "TRICEPS",
    "triceps-b": "TRICEPS",
    "back-lats-a": "LATS",
    "back-lats-b": "LATS",
    "back-lower": "BACK",
    "back-forearms-a": null,
    "back-forearms-b": null,
    "back-glutes": null,
    "back-hamstrings-a": "QUADS",
    "back-hamstrings-b": "QUADS",
    "back-calves-a": null,
    "back-calves-b": null,
  };

  opacity = (imgId, workoutMuscles) => {
    let points = workoutMuscles[this.MUSCLE_MAP[imgId]];
    if (!points) {
      return "no-exercise";
    } else if (points < 1000) {
      return "low-exercise";
    } else if (points < 3000) {
      return "medium-exercise";
    } else if (points < 5000) {
      return "high-exercise";
    }
  };

  render() {
    const { classes } = this.props;
    const { isFetching, data, modal } = this.state;
    return isFetching ? (
      <div align="center">
        <CircularProgress color="secondary" />
      </div>
    ) : (
      <div>
        <Grid container spacing={1}>
          {data.single &&
            data.single.map((stat) => {
              return (
                <Grid item key={stat.title} xs={12} sm={6} md={3}>
                  <Card>
                    <CardHeader color="warning" stats icon>
                      <CardIcon color="rose">
                        <Icon className="animated tada">{stat.icon}</Icon>
                      </CardIcon>
                      <p className={classes.cardCategory}>{stat.title}</p>
                      <h3 className={classes.cardTitle}>{stat.value}</h3>
                    </CardHeader>
                    <CardBody>
                      {stat.description && (
                        <div>
                          <span dangerouslySetInnerHTML={{ __html: stat.description }} />
                        </div>
                      )}
                      {stat.muscles && (
                        <div id="malefigures">
                          <div id="muscle-map">
                            <img id="background" src="assets/muscles/00.-Blank-Figures.png" />
                            <img
                              id="traps-a"
                              src="assets/muscles/08.-TrapsLeft.png"
                              className={this.opacity("traps-a", stat.muscles)}
                            />
                            <img
                              id="traps-b"
                              src="assets/muscles/08.-TrapsRight.png"
                              className={this.opacity("traps-b", stat.muscles)}
                            />
                            <img
                              id="shoulders-a"
                              src="assets/muscles/07.A-Deltoids.png"
                              className={this.opacity("shoulders-a", stat.muscles)}
                            />
                            <img
                              id="shoulders-b"
                              src="assets/muscles/07.B-Deltoids.png"
                              className={this.opacity("shoulders-b", stat.muscles)}
                            />
                            <img
                              id="pecs"
                              src="assets/muscles/06.-Pecs.png"
                              className={this.opacity("pecs", stat.muscles)}
                            />
                            <img
                              id="biceps-a"
                              src="assets/muscles/05.A-Biceps.png"
                              className={this.opacity("biceps-a", stat.muscles)}
                            />
                            <img
                              id="biceps-b"
                              src="assets/muscles/05.B-Biceps.png"
                              className={this.opacity("biceps-b", stat.muscles)}
                            />
                            <img
                              id="forearm-a"
                              src="assets/muscles/14.A-Forearms.png"
                              className={this.opacity("forearm-a", stat.muscles)}
                            />
                            <img
                              id="forearm-b"
                              src="assets/muscles/14.B-Forearms.png"
                              className={this.opacity("forearm-b", stat.muscles)}
                            />
                            <img
                              id="obliques"
                              src="assets/muscles/04.-Obliques.png"
                              className={this.opacity("obliques", stat.muscles)}
                            />
                            <img
                              id="quads-a"
                              src="assets/muscles/01.A-Quads.png"
                              className={this.opacity("quads-a", stat.muscles)}
                            />
                            <img
                              id="quads-b"
                              src="assets/muscles/01.B-Quads.png"
                              className={this.opacity("quads-b", stat.muscles)}
                            />
                            <img
                              id="calves-a"
                              src="assets/muscles/13.A-Calves.png"
                              className={this.opacity("calves-a", stat.muscles)}
                            />
                            <img
                              id="calves-b"
                              src="assets/muscles/13.B-Calves.png"
                              className={this.opacity("calves-b", stat.muscles)}
                            />
                            <img
                              id="back-traps-a"
                              src="assets/muscles/08.B-Traps.png"
                              className={this.opacity("back-traps-a", stat.muscles)}
                            />
                            <img
                              id="back-traps-b"
                              src="assets/muscles/08.C-Traps.png"
                              className={this.opacity("back-traps-b", stat.muscles)}
                            />
                            <img
                              id="back-shoulders-a"
                              src="assets/muscles/07.C-Deltoids.png"
                              className={this.opacity("back-shoulders-a", stat.muscles)}
                            />
                            <img
                              id="back-shoulders-b"
                              src="assets/muscles/07.D-Deltoids.png"
                              className={this.opacity("back-shoulders-b", stat.muscles)}
                            />
                            <img
                              id="triceps-a"
                              src="assets/muscles/09.A-Triceps.png"
                              className={this.opacity("triceps-a", stat.muscles)}
                            />
                            <img
                              id="triceps-b"
                              src="assets/muscles/09.B-Triceps.png"
                              className={this.opacity("triceps-b", stat.muscles)}
                            />
                            <img
                              id="back-lats-a"
                              src="assets/muscles/10.A-Lats.png"
                              className={this.opacity("back-lats-a", stat.muscles)}
                            />
                            <img
                              id="back-lats-b"
                              src="assets/muscles/10.B-Lats.png"
                              className={this.opacity("back-lats-b", stat.muscles)}
                            />
                            <img
                              id="back-lower"
                              src="assets/muscles/15.-Lower-Back.png"
                              className={this.opacity("back-lower", stat.muscles)}
                            />
                            <img
                              id="back-forearms-a"
                              src="assets/muscles/14.C-Forearms.png"
                              className={this.opacity("back-forearms-a", stat.muscles)}
                            />
                            <img
                              id="back-forearms-b"
                              src="assets/muscles/14.D-Forearms.png"
                              className={this.opacity("back-forearms-b", stat.muscles)}
                            />
                            <img
                              id="back-glutes"
                              src="assets/muscles/11.-Glutes.png"
                              className={this.opacity("back-glutes", stat.muscles)}
                            />
                            <img
                              id="back-hamstrings-a"
                              src="assets/muscles/12.A-Hamstrings.png"
                              className={this.opacity("back-hamstrings-a", stat.muscles)}
                            />
                            <img
                              id="back-hamstrings-b"
                              src="assets/muscles/12.B-Hamstrings.png"
                              className={this.opacity("back-hamstrings-b", stat.muscles)}
                            />
                            <img
                              id="back-calves-a"
                              src="assets/muscles/13.C-Calves.png"
                              className={this.opacity("back-calves-a", stat.muscles)}
                            />
                            <img
                              id="back-calves-b"
                              src="assets/muscles/13.D-Calves.png"
                              className={this.opacity("back-calves-b", stat.muscles)}
                            />
                          </div>
                        </div>
                      )}
                    </CardBody>
                    <CardFooter>
                      <div className={classes.chart}>{stat.footer}</div>
                      {stat.action && this.createAction(stat.action)}
                    </CardFooter>
                  </Card>
                </Grid>
              );
            })}

          {data.ranking && (
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardHeader color="primary">{data.ranking.title}</CardHeader>
                <CardContent>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Points</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.ranking.results.map((result, i) => {
                        return (
                          <TableRow key={i} onClick={() => this.handleModalOpen(result.name)}>
                            <TableCell>
                              {i + 1}. {result.name}{" "}
                              {i === 0 && (
                                <span role="img" aria-label="trophy">
                                  🏆
                                </span>
                              )}
                            </TableCell>
                            <TableCell>{result.points}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </CardContent>
                <CardFooter>
                  <div className={classes.chart}>{data.ranking.description}</div>
                </CardFooter>
              </Card>
              <Dialog open={modal.open} onClose={this.handleModalClose}>
                <DialogTitle>{modal.title}</DialogTitle>
                <DialogContent>{modal.body}</DialogContent>
                <DialogActions>
                  <Button onClick={this.handleModalClose} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
          {data.tables &&
            data.tables.map((table) => {
              return (
                <Grid item key={table.title} xs={12} sm={6} md={3}>
                  <Card>
                    <CardHeader color="primary">{table.title}</CardHeader>
                    <CardContent>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            {table.headers.map((header, i) => {
                              return <TableCell key={i}>{header}</TableCell>;
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {table.rows.map((row, i) => {
                            return (
                              <TableRow key={i}>
                                {row.map((value, j) => {
                                  return <TableCell key={j}>{value}</TableCell>;
                                })}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </CardContent>
                    <CardFooter>
                      <div className={classes.chart}>{table.description}</div>
                    </CardFooter>
                  </Card>
                </Grid>
              );
            })}
          {data.charts &&
            data.charts.map((chart) => {
              return (
                <Grid item key={chart.title} xs={12} sm={12} md={4}>
                  <Card chart>
                    <CardHeader color="success">
                      <ChartistGraph
                        className="ct-chart"
                        data={chart.data}
                        type="Line"
                        options={dailySalesChart.options}
                        listener={dailySalesChart.animation}
                      />
                    </CardHeader>
                    <CardContent>
                      <h4 className={classes.cardTitle}>{chart.title}</h4>
                      <p className={classes.cardCategory}>
                        {/*<span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> 1%
                  </span>{" "}
                  increase in last workout.*/}
                      </p>
                    </CardContent>
                    <CardFooter chart>
                      <div className={classes.chart}>{chart.description}</div>
                    </CardFooter>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </div>
    );
  }
}

Summary.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Summary);
