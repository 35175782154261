import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { BottomNavigation } from "@material-ui/core";
import { BottomNavigationAction } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { Link as RouterLink } from "react-router-dom";

const styles = {
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 99999,
    paddingTop: "10px",
    borderTop: "1px solid #f8f8f8",
  },
};

function Footer({ ...props }) {
  const { classes } = props;
  const value = props.location.pathname;

  return (
    <BottomNavigation value={value} showLabels className={classes.root}>
      <BottomNavigationAction
        label="Summary"
        value="/summary"
        component={RouterLink}
        to="/summary"
        icon={<Icon>trending_up</Icon>}
      />
      <BottomNavigationAction
        label="Workout"
        value="/new-workout"
        component={RouterLink}
        to="/new-workout"
        icon={<Icon>fitness_center</Icon>}
      />
      <BottomNavigationAction
        label="Profile"
        value="/profile"
        component={RouterLink}
        to="/profile"
        icon={<Icon>person</Icon>}
      />
    </BottomNavigation>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
