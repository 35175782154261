import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Serie from "./Serie.jsx";
import TimeSerie from "./TimeSerie.jsx";

class Exercise extends React.Component {
  render() {
    const { id, type, series, progress } = this.props;

    return (
      <div>
        <div>
          {series.map((data, i) => {
            return (
              <div key={i}>
                {(() => {
                  switch (type) {
                    case "TIME_BASED":
                    case "CARDIO":
                      return (
                        <TimeSerie
                          exerciseId={id}
                          index={i}
                          repetitions={data.repetitions}
                          weight={data.weight}
                          progress={progress[i]}
                          done={data.done}
                          onRepetitionsChange={this.props.onRepetitionsChange}
                          onWeightChange={this.props.onWeightChange}
                          onDoneChange={this.props.onDoneChange}
                        />
                      );
                    default:
                      return (
                        <Serie
                          exerciseId={id}
                          index={i}
                          repetitions={data.repetitions}
                          weight={data.weight}
                          progress={progress[i]}
                          done={data.done || false}
                          onRepetitionsChange={this.props.onRepetitionsChange}
                          onWeightChange={this.props.onWeightChange}
                          onDoneChange={this.props.onDoneChange}
                        />
                      );
                  }
                })()}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

Exercise.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  series: PropTypes.array.isRequired,
  progress: PropTypes.array,
  onRepetitionsChange: PropTypes.func.isRequired,
  onWeightChange: PropTypes.func.isRequired,
  onDoneChange: PropTypes.func.isRequired,
};

export default withStyles({})(Exercise);
